/* eslint-disable no-console */

import { register } from 'register-service-worker'

register(`${process.env.BASE_URL}service-worker.js`, {
  registered () {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.controller?.postMessage({
        type: 'SESSION_STORAGE_DATA',
        value: JSON.parse(sessionStorage.getItem('customHeaders') ?? '{}')
      })
      navigator.serviceWorker.controller?.postMessage({
        type: 'SET_ORIGIN',
        value: window.location.origin
      })
    }
  },
  error (error) {
    console.error('Error during service worker registration:', error)
  }
})
