<template>
    <div v-show="areUnitsLoaded" class="graph-legend">
        <span
            v-for="(label, index) in newLabels"
            v-bind:key="label"
            :style="`color: ${colors[index]}`"
        >{{ label + (index+1 !== newLabels.length ? "&nbsp;/&nbsp;" : "") }}</span>
    </div>
</template>

<script lang="ts">
import { Vue, Component, Watch, Prop, toNative } from 'vue-facing-decorator'
import store from '@/stores'

@Component
class GraphLegend extends Vue {
    @Prop labels!: string[]
    @Prop colors!: string[]

    private newLabels = []
    private loaded = false

    get areUnitsLoaded (): boolean {
      return store.getters.areUnitsLoaded && this.loaded
    }

    getUnit (productName: string) {
      const unit = store.getters.getUnit(productName.split(' ')[0])
      if (unit) return `${productName} (${unit})`
      return productName
    }

    @Watch('areUnitsLoaded')
    onLoadedStateChange () {
      this.newLabels = this.labels.map((item: string) => this.getUnit(item)) as any
      this.newLabels.shift()
    }

    mounted () {
      this.loaded = true
    }
}
export default toNative(GraphLegend)
</script>

<style>

</style>
