import { ApolloClient, NormalizedCacheObject } from '@apollo/client'

export function convertCamelCaseToSnakeCase (input: string | null): string | null {
  if (input) {
    return input.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`)
  } return null
}

export function convertUndefinedToNull (value: any): any {
  if (value === undefined) {
    return null
  } else {
    return value
  }
}

export function camelCaseToTitle (value: string): string {
  if (value) {
    const titleCase = value.replace(/([A-Z])/gm, ' $1').trim()
    return titleCase.charAt(0).toUpperCase() + titleCase.slice(1)
  } return ''
}

export function toFloat (value: any): number {
  return isNaN(parseFloat(value as string)) ? value as number : parseFloat(value as string)
}

export function evictCache (
  apolloClient: ApolloClient<NormalizedCacheObject>, queryNames: string[],
  broadcast = true
) {
  const fields: {[key: string]: any} = {}

  for (const queryName of queryNames) {
    fields[queryName] = (refs: any, { DELETE }: any) => DELETE
  }

  return apolloClient.cache.modify({
    id: 'ROOT_QUERY',
    fields,
    broadcast
  })
}
