export default {
  namespaced: true,
  state () {
    return {
      model: null
    }
  },
  mutations: {
    setModel (state: any, model: any) {
      state.model = model
    },
    resetStoreState (state: any) {
      state.well = null
    }
  }
}
