<template>
    <v-row class="h-screen" align="center" justify="center">
        <v-progress-circular size="70" width="7" color="primary" indeterminate></v-progress-circular>
    </v-row>
</template>

<script lang="ts">
import { Vue, Component, toNative } from 'vue-facing-decorator'
    @Component
class Loading extends Vue {}
export default toNative(Loading)
</script>

<style>

</style>
