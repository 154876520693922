import Company from '@/models/Company'

export default {
  namespaced: true,
  state () {
    return {
      companyInfo: null as null | Company
    }
  },
  mutations: {
    setCompanyInfo (state: any, companyInfo: any) {
      state.companyInfo = companyInfo
    },
    resetStoreState (state: any) {
      state.companyInfo = null
    }
  }
}
