import UserAccount, { UserTenancy } from '../base/UserAccount'

export enum RoleLevel {
  None = 0,
  Read = 1,
  Write = 2,
  TenantAdmin = 3,
  SystemAdmin = 4
}

export enum Module {
  AssetManagement = 'ASSET_MGMT'
}

const checkModuleAccess = (userTenantToCheck: UserTenancy, requiredModule: Module) => {
  const hasRequiredModuleAccess: boolean = Object.prototype.hasOwnProperty.call(userTenantToCheck.modules, requiredModule.valueOf())

  if (userTenantToCheck.tenantAdmin) {
    return RoleLevel.TenantAdmin
  } else if (hasRequiredModuleAccess) {
    return getRoleFromModule(userTenantToCheck, requiredModule)
  } else {
    return RoleLevel.None
  }
}

const getRoleFromModule = (userTenantToCheck: UserTenancy, requiredModule: Module) => {
  const roles: object = userTenantToCheck.modules[requiredModule.valueOf()].roles

  if (Object.prototype.hasOwnProperty.call(roles, 'WRITE')) {
    return RoleLevel.Write
  } else if (Object.prototype.hasOwnProperty.call(roles, 'READ')) {
    return RoleLevel.Read
  } else {
    return RoleLevel.None
  }
}

export default {
  authorize: (minimumRoleLevel: RoleLevel, requiredModule: Module | null, user: UserAccount | null, schemaName: string | null) => {
    let userRoleLevel: RoleLevel = RoleLevel.None

    if (user !== null) {
      if (user.systemAdmin) {
        userRoleLevel = RoleLevel.SystemAdmin
      } else if (requiredModule !== null) {
        userRoleLevel = checkModuleAccess(user.tenants[schemaName as string], requiredModule)
      }
    }

    return userRoleLevel.valueOf() >= minimumRoleLevel.valueOf()
  }
}
