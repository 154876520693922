/* eslint-disable import/export */
import Dygraph from 'dygraphs'
import { Vue, Component, Watch } from 'vue-facing-decorator'
import store from '@/stores'
import { ApolloComponentQueries } from './DataTableMixin'

export default interface GraphMixin {
    readonly vuexModuleName: string
}

@Component
export default class GraphMixin extends Vue {
  protected loaded = false
  protected graph!: Dygraph
  protected logscale = true

  get rowNum () {
    return (store.state[this.vuexModuleName] as any).rowNum as number
  }

  get apollo (): ApolloComponentQueries {
    return {}
  }

  changeScale () {
    this.logscale = !this.logscale
    this.graph.updateOptions({ logscale: this.logscale })
  }

    @Watch('rowNum')
  highlightProductionPoint () {
    if (this.rowNum !== null) {
      this.graph.setSelection(this.rowNum)
    }
  }

    resetZoom (): void {
      this.graph.resetZoom()
    }

    created () {
      Object.keys(this.apollo ?? {}).forEach(key => {
        this.$apollo.addSmartQuery(key, this.apollo![key])
      })
    }
}
