export interface TabState {
  tab: number
  refresh: boolean
}

export default {
  namespaced: true,
  state () {
    return {
      tab: 0,
      refresh: false
    } as TabState
  },
  mutations: {
    setTab (state: any, tab: number) {
      state.tab = tab
    },
    setRefresh (state: any, value: boolean) {
      state.refresh = value
    },
    resetStoreState (state: any) {
      state.tab = 0
    }
  }
}
