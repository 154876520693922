import { resolveComponent as _resolveComponent, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "text-subtitle-2"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_skeleton_loader = _resolveComponent("v-skeleton-loader")!
  const _component_WellListItem = _resolveComponent("WellListItem")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_list_subheader = _resolveComponent("v-list-subheader")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createElementBlock("div", null, [
    _withDirectives(_createVNode(_component_v_skeleton_loader, {
      type: "image",
      width: "100%",
      height: "100%"
    }, null, 512), [
      [_vShow, !_ctx.loaded]
    ]),
    (_ctx.loaded && _ctx.wells.length === 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, "There are currently no well updates in the system"))
      : _createCommentVNode("", true),
    _withDirectives(_createVNode(_component_v_card, { class: "py-2" }, {
      default: _withCtx(() => [
        _createVNode(_component_v_row, { wrap: "" }, {
          default: _withCtx(() => [
            _createVNode(_component_v_col, {
              xs12: "",
              lg6: "",
              class: "pa-0 ma-0 pr-3"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_list, { lines: "two" }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getMeterWellsHalf(1), (well, index) => {
                      return (_openBlock(), _createBlock(_component_v_list_subheader, {
                        class: "px-0 py-2",
                        key: well.id
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_WellListItem, { well: well }, null, 8, ["well"]),
                          (index !== _ctx.getMeterWellsHalf(1).length - 1)
                            ? (_openBlock(), _createBlock(_component_v_divider, {
                                key: 0,
                                class: "mt-2"
                              }))
                            : _createCommentVNode("", true)
                        ]),
                        _: 2
                      }, 1024))
                    }), 128))
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_v_col, {
              xs12: "",
              lg6: "",
              class: "pa-0 ma-0 pr-3"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_list, { lines: "two" }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getMeterWellsHalf(2), (well, index) => {
                      return (_openBlock(), _createBlock(_component_v_list_subheader, {
                        class: "px-0 py-2",
                        key: well.id
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_WellListItem, { well: well }, null, 8, ["well"]),
                          (index !== _ctx.getMeterWellsHalf(2).length - 1)
                            ? (_openBlock(), _createBlock(_component_v_divider, {
                                key: 0,
                                class: "mt-2"
                              }))
                            : _createCommentVNode("", true)
                        ]),
                        _: 2
                      }, 1024))
                    }), 128))
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 512), [
      [_vShow, _ctx.loaded && _ctx.wells.length > 0]
    ])
  ]))
}