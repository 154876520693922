import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "d-flex flex-column flex-1-1" }
const _hoisted_2 = { class: "pt-1 text-right" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_list_item_title = _resolveComponent("v-list-item-title")!
  const _component_v_list_item_subtitle = _resolveComponent("v-list-item-subtitle")!
  const _component_v_chip = _resolveComponent("v-chip")!
  const _component_v_list_item = _resolveComponent("v-list-item")!

  return (_openBlock(), _createBlock(_component_v_list_item, {
    class: "py-0 w-100",
    key: _ctx.well.id,
    to: {name: 'Well Details', params: { id: _ctx.well.id, tenant: _ctx.$store.state.currentTenant?.schemaName}}
  }, {
    append: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_v_chip, {
          class: _normalizeClass([['ben-chip-status'], "align-self-end"])
        }, {
          default: _withCtx(() => [
            _createElementVNode("span", {
              class: "ben-chip-status-color",
              style: _normalizeStyle({ background: _ctx.well.wellStatus.color?.hexCode })
            }, null, 4),
            _createTextVNode(" " + _toDisplayString(_ctx.well.wellStatus.name), 1)
          ]),
          _: 1
        }),
        _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.generateUpdatedCreatedString(_ctx.well)), 1)
      ])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_v_list_item_title, { class: "text-body-1 font-weight-medium" }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.getWellLabel(_ctx.well)), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_v_list_item_subtitle, { class: "text-body-2" }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.well.wellType.name), 1)
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["to"]))
}