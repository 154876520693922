export default {
  namespaced: true,
  state () {
    return {
      aliasRowNum: null
    }
  },
  mutations: {
    setAliasRowNum (state: any, rowNum: any) {
      state.aliasRowNum = rowNum
    },
    resetStoreState (state: any) {
      state.aliasRowNum = null
    }
  }
}
