import Meter from '@/models/Meter'

export default {
  namespaced: true,
  state () {
    return {
      model: null as Meter | null,
      productRowNum: null,
      meterWellHistoryRowNum: null,
      meterPipelineHistoryRowNum: null,
      interestRowNum: null,
      aliasRowNum: null,
      analysisGasRowNum: null
    }
  },
  mutations: {
    setModel (state: any, model: any) {
      state.model = model
    },
    setProductionRowNum (state: any, rowNum: any) {
      state.productRowNum = rowNum
    },
    setAnalysisGasRowNum (state: any, rowNum: any) {
      state.analysisGasRowNum = rowNum
    },
    setMeterWellHistoryRowNum (state: any, rowNum: any) {
      state.meterWellHistoryRowNum = rowNum
    },
    setMeterPipelineHistoryRowNum (state: any, rowNum: any) {
      state.meterPipelineHistoryRowNum = rowNum
    },
    setInterestRowNum (state: any, rowNum: any) {
      state.interestRowNum = rowNum
    },
    setAliasRowNum (state: any, rowNum: any) {
      state.aliasRowNum = rowNum
    },
    resetStoreState (state: any) {
      state.model = null
      state.productRowNum = null
      state.meterWellHistoryRowNum = null
      state.interestRowNum = null
      state.aliasRowNum = null
    }
  }
}
