<template>
    <v-app-bar color="white" theme="light" height="48" :order="2">
        <v-toolbar-items v-if="!navDrawerOpen">
            <v-btn variant="text" size="small" @click.stop="openNavDrawer()">
                <v-icon>mdi-menu</v-icon>
            </v-btn>
        </v-toolbar-items>
        <v-breadcrumbs :items="breadcrumbs" class="pl-3">
            <template v-slot:item="breadProps" v-if="currentTenant !== undefined && !(isInErrorState || isInMaintenanceState || isInSwitchoverState)">
              <v-breadcrumbs-item class="nav-breadcrumbs" :to="breadProps.item.route">
                  <v-menu content-class="ben-menu" offset="15" min-width="160" max-width="260"
                          v-if="breadProps.item.selectTenant">
                      <template v-slot:activator="{ props }">
                          <div v-bind="props">
                              <v-btn class="pl-0 pr-0" variant="text">{{ breadProps.item.text }} &nbsp;
                                  <v-icon>mdi-menu-down</v-icon>
                              </v-btn>
                          </div>
                      </template>
                      <v-list>
                          <v-list-item v-for="item in userCustomData !== null ? userCustomData.tenants : []"
                                        v-bind:key="item.id"
                                        @click="changeTenant(item)">

                                  <v-list-item-title>{{ item.displayName }}</v-list-item-title>

                          </v-list-item>
                      </v-list>
                  </v-menu>
                  <div v-else>
                      <v-btn class="pl-0" variant="text" disabled>{{ breadProps.item.text }}</v-btn>
                  </div>
              </v-breadcrumbs-item>
            </template>
        </v-breadcrumbs>
        <v-spacer></v-spacer>
        <v-toolbar-items class="hidden-sm-and-down">
            <Notifications></Notifications>
            <v-menu content-class="ben-menu" offset="5" min-width="160" max-width="260">
                <template v-slot:activator="{ props }">
                    <v-btn variant="text" v-bind="props">{{ fullName }}
                        <v-icon class="pl-2">mdi-account</v-icon>
                    </v-btn>
                </template>
                <v-list>
                    <v-list-item class="py-1">

                            <v-list-item-title class="ben-menu__item-title--small mb-1">{{ fullName }}
                            </v-list-item-title>
                            <v-list-item-subtitle>{{ userCustomData !== null ? userCustomData.email : "" }}
                            </v-list-item-subtitle>

                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item :to="{name: 'My Account'}" :disabled="isInErrorState || isInMaintenanceState">

                            <v-list-item-title>My Account</v-list-item-title>

                    </v-list-item>
                    <!-- <v-list-item @click.stop="openDocumentation()">

                            <v-list-item-title>Documentation</v-list-item-title>

                    </v-list-item> -->
                    <v-list-item @click.stop="openFeedbackForm()">

                            <v-list-item-title>Send Feedback</v-list-item-title>

                    </v-list-item>
                    <v-list-item @click="$store.dispatch('logOut')">

                            <v-list-item-title>Log Out</v-list-item-title>

                    </v-list-item>
                    <v-list-item v-if="userCustomData?.testAccount" @click="toggleValidation">

                      <v-list-item-title v-if="validating">Prod Version <span style="color:blue"> (Blue) </span></v-list-item-title>
                      <v-list-item-title v-else>Staging Version <span style="color:green"> (Green) </span></v-list-item-title>

                    </v-list-item>
                </v-list>
            </v-menu>
        </v-toolbar-items>
    </v-app-bar>
</template>

<script lang="ts">
import Notifications from '@/components/Notifications.vue'
import UserAccount from '@/models/base/UserAccount'
import { isValidating, setValidationHeaders } from '@/models/static/ValidationUtils'
import store from '@/stores'
import { Component, Vue, toNative } from 'vue-facing-decorator'

@Component({
  components: { Notifications }
})
class Navbar extends Vue {
  protected validating = false

  $socket: any

  get currentTenant () {
    return store.state.currentTenant?.displayName
  }

  get userCustomData (): null | UserAccount {
    return store.getters.userCustomData
  }

  get breadcrumbs () {
    const breadcrumbs = [
      {
        text: this.currentTenant,
        selectTenant: true
      },
      {
        text: this.$route.name ?? '',
        route: this.$route.path,
        selectTenant: false
      }
    ]

    return breadcrumbs
  }

  get fullName (): string | null {
    if (this.userCustomData === null) {
      return null
    } else {
      return this.userCustomData.firstName + ' ' + this.userCustomData.lastName
    }
  }

  get navDrawerOpen (): boolean {
    return store.state.navDrawerOpen
  }

  set navDrawerOpen (navDrawerOpen: boolean) {
    store.commit('setNavDrawerOpen', navDrawerOpen)
  }

  get isInErrorState (): boolean {
    return this.$route.path === '/error'
  }

  get isInMaintenanceState (): boolean {
    return this.$route.path === '/maintenance'
  }

  get isInSwitchoverState (): boolean {
    return this.$route.path === '/switchover'
  }

  mounted () {
    if (isValidating()) {
      this.validating = true
    }
  }

  changeTenant (tenant: {schemaName: string}): void {
    this.$router.push({ name: 'Dashboard', params: { tenant: tenant.schemaName } })
  }

  openFeedbackForm (): void {
    window.open('https://line45.atlassian.net/rest/collectors/1.0/template/form/1291fefd?os_authType=none#', '_blank')
  }

  openDocumentation (): void {
    window.open(process.env.VUE_APP_DOCS_URL, '_blank')
  }

  openNavDrawer (): void {
    this.navDrawerOpen = true
  }

  resetAppState (): void {
    sessionStorage.clear()
    window.location.reload()
  }

  async openValidation () {
    await setValidationHeaders()
    this.$socket.close()
  }

  toggleValidation (): void {
    if (this.validating) {
      this.resetAppState()
    } else {
      this.openValidation()
    }
  }
}
export default toNative(Navbar)
</script>

<style>

</style>
