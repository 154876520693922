export default {
  namespaced: true,
  state () {
    return {
      model: null,
      productionRowNum: null,
      meterProduction: null,
      productionForGraph: [],
      productionAllocation: null,
      aliasRowNum: null
    }
  },
  mutations: {
    setModel (state: any, model: any) {
      state.model = model
    },
    setProductionRowNum (state: any, productionRowNum: any) {
      state.productionRowNum = productionRowNum
    },
    setMeterProduction (state: any, meterProduction: any) {
      state.meterProduction = meterProduction
    },
    setProductionForGraph (state: any, productionForGraph: any) {
      state.productionForGraph = productionForGraph
    },
    setProductionAllocation (state: any, productionAllocation: any) {
      state.productionAllocation = productionAllocation
    },
    setAliasRowNum (state: any, rowNum: any) {
      state.aliasRowNum = rowNum
    },
    resetStoreState (state: any) {
      state.model = null
      state.productionRowNum = null
      state.meterProduction = null
      state.productionForGraph = []
      state.productionAllocation = null
      state.aliasRowNum = null
    }
  }
}
