export default {
  namespaced: true,
  state () {
    return {
      model: null,
      interestRowNum: null,
      aliasRowNum: null,
      meterPipelineHistoryRowNum: null
    }
  },
  mutations: {
    setModel (state: any, model: any) {
      state.model = model
    },
    setMeterPipelineHistoryRowNum (state: any, rowNum: any) {
      state.meterPipelineHistoryRowNum = rowNum
    },
    resetStoreState (state: any) {
      state.model = null
      state.interestRowNum = null
      state.aliasRowNum = null
    },
    setInterestRowNum (state: any, rowNum: any) {
      state.interestRowNum = rowNum
    },
    setAliasRowNum (state: any, rowNum: any) {
      state.aliasRowNum = rowNum
    }
  }
}
