import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_badge = _resolveComponent("v-badge")!
  const _component_v_btn = _resolveComponent("v-btn")!

  return (_openBlock(), _createBlock(_component_v_btn, {
    variant: "text",
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.notificationDrawerOpen = !_ctx.notificationDrawerOpen)),
    key: _ctx.refreshKey
  }, {
    default: _withCtx(() => [
      (_ctx.$store.getters['Notifications/unreadCount'] > 0)
        ? (_openBlock(), _createBlock(_component_v_badge, {
            key: 0,
            color: "#E62233",
            content: _ctx.$store.getters['Notifications/unreadCount'],
            size: "large"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_icon, null, {
                default: _withCtx(() => [
                  _createTextVNode("mdi-bell-outline")
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["content"]))
        : (_openBlock(), _createBlock(_component_v_icon, { key: 1 }, {
            default: _withCtx(() => [
              _createTextVNode("mdi-bell-outline")
            ]),
            _: 1
          }))
    ]),
    _: 1
  }))
}