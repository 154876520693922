<template>
    <v-btn  variant="text"
            v-on:click="notificationDrawerOpen = !notificationDrawerOpen"
            :key="refreshKey"
    >
        <v-badge  v-if="$store.getters['Notifications/unreadCount'] > 0"
                  color="#E62233"
                  :content="$store.getters['Notifications/unreadCount']"
                  size="large"
        >
            <v-icon>mdi-bell-outline</v-icon>
        </v-badge>
        <v-icon v-else>mdi-bell-outline</v-icon>
    </v-btn>
</template>

<script lang="ts">
import { Vue, Component, Watch, toNative } from 'vue-facing-decorator'
import Notification from '@/models/Notification'
import store from '@/stores'

@Component
class Notifications extends Vue {
  protected refreshKey = 0

  get notifications (): Notification[] {
    return store.state.Notifications.notifications
  }

  get currentTenantSchema (): string | null {
    if (store.state.currentTenant) {
      return store.state?.currentTenant?.schemaName
    } else {
      return null
    }
  }

  get notificationDrawerOpen (): boolean {
    return store.state.notificationDrawerOpen
  }

  set notificationDrawerOpen (notificationDrawerOpen: boolean) {
    store.commit('setNotificationDrawerOpen', notificationDrawerOpen)
  }

  @Watch('notifications')
  onNotificationsUpdate () {
    this.refreshKey++
  }

  @Watch('notificationDrawerOpen')
  onNotificationDrawerOpenChanged (drawerOpen: boolean, drawerPreviouslyOpen: boolean): void {
    if (drawerOpen && !drawerPreviouslyOpen) {
      store.dispatch('Notifications/markNotificationsRead')
    }
  }
}
export default toNative(Notifications)
</script>@/stores
