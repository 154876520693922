import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_progress_circular = _resolveComponent("v-progress-circular")!
  const _component_v_row = _resolveComponent("v-row")!

  return (_openBlock(), _createBlock(_component_v_row, {
    class: "h-screen",
    align: "center",
    justify: "center"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_progress_circular, {
        size: "70",
        width: "7",
        color: "primary",
        indeterminate: ""
      })
    ]),
    _: 1
  }))
}