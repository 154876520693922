import gql from 'graphql-tag'
import { apolloClient } from '../vue-apollo'
import store from '@/stores/global'
import router from '../router'
import Toast from './components/Toast.vue'

interface Message {
    action: string
    message?: string
}

interface WebsocketMessage extends Message {
    data: any
}

interface AppStatusMessage extends Message {
    app_version: null | string,
    app_status: number,
    message: string
}

export default class WebsocketManager {
  toast: typeof Toast & HTMLElement

  constructor (toast: typeof Toast & HTMLElement) {
    this.toast = toast
  }

  handleMessage (message: Message) {
    if (message.action === 'getConnectionId' && store.state.currentTenant) {
      this.handleGetConnectionIDMessage(message as WebsocketMessage)
    } else if (message.action === 'pushNotification') {
      this.handlePushNotificationMessage(message as WebsocketMessage)
    } else if (message.action === 'appStatusUpdate') {
      this.handleAppStatusUpdate(message as AppStatusMessage)
    } else if (message.action === 'disableUser') {
      this.toast.warning(message.message as string)
      setTimeout(() => {
        if (store.getters.userCustomData) {
          store.dispatch('logOut')
          this.toast.close()
        }
      }, 10000)
    }
  }

  handleGetConnectionIDMessage (message: WebsocketMessage) {
    const connectionId: string = message.data

    const mutation: any = gql`mutation ($connectionId: String!) {
                                    registerWebsocketConnection (connectionId: $connectionId) {
                                        errors
                                    }
                                }`

    const variables: object = {
      connectionId
    }

    apolloClient.mutate({
      mutation,
      variables
    }).catch(e => {
      store.commit('addFatalError', e.message)
    })
  }

  handlePushNotificationMessage (message: WebsocketMessage) {
    if (message.data &&
            Object.prototype.hasOwnProperty.call(message.data, 'schemaName') &&
            store.state.currentTenant &&
            message.data.schemaName === store.state.currentTenant.schemaName) {
      store.dispatch('Notifications/pushNotification', message.data)
    }
  }

  handleAppStatusUpdate (message: AppStatusMessage) {
    const reload = () => window.location.replace(window.location.origin)
    const time = 5000 * 60 // 5 minutes

    switch (message.app_status) {
      case -1:
        this.toast.error(message.message, setTimeout(() => reload(), time), reload)
        break
      case 0:
        this.toast.warning(message.message)
        setTimeout(() => {
          if (store.getters.userCustomData) { router.push({ name: 'Maintenance' }) }
        }, time)
        break
      case 1:
        this.toast.success(message.message, setTimeout(() => reload(), time), reload)
        break
      case 2:
        this.toast.warning(message.message)
        setTimeout(() => {
          if (store.getters.userCustomData) { router.push({ name: 'Switchover' }) }
        }, 2000 * 60) // 2 minutes
        break
    }
  }
}
