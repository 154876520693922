export default abstract class DataStructureUtils {
  static getArrayPage (list: any[], pageSize: number, page: number): any[] {
    return list.slice(pageSize * (page - 1), pageSize * page)
  }

  static getArrayFraction (list: any[], divisor: number, page: number): any[] {
    const pageSize: number = Math.ceil(list.length / divisor)
    return this.getArrayPage(list, pageSize, page)
  }
}
