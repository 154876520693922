export default {
  namespaced: true,
  state () {
    return {
      dataLoaded: false,
      mapLoaded: false,
      map: null,
      detailDrawerIsOpen: false,
      selectedAssetID: null,
      selectedAssetType: null,
      selectedAsset: null,
      selectedStyle: 'satellite',
      totalDistance: null as null | string,
      measureToolOn: false,
      measureGeojson: {
        type: 'FeatureCollection',
        features: []
      },
      measureLinestring: {
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: []
        }
      }
    }
  },
  mutations: {
    setDataLoaded (state: any, dataLoaded: boolean) {
      state.dataLoaded = dataLoaded
    },
    setMapLoaded (state: any, mapLoaded: boolean) {
      state.mapLoaded = mapLoaded
    },
    setMap (state: any, map: any) {
      state.map = map
    },
    setDetailDrawerIsOpen (state: any, detailDrawerIsOpen: boolean) {
      state.detailDrawerIsOpen = detailDrawerIsOpen
    },
    setSelectedAssetID (state: any, selectedAssetID: number) {
      state.selectedAssetID = selectedAssetID
    },
    setSelectedAssetType (state: any, selectedAssetType: string) {
      state.selectedAssetType = selectedAssetType
    },
    setSelectedAsset (state: any, selectedAsset: object) {
      state.selectedAsset = selectedAsset
    },
    setSelectedStyle (state: any, selectedStyle: object) {
      state.selectedStyle = selectedStyle
    },
    setTotalDistance (state: any, totalDistance: string) {
      state.totalDistance = totalDistance
    },
    setMeasureTool (state: any, measureToolOn: boolean) {
      state.measureToolOn = measureToolOn
    },
    setMeasureGeojson (state: any, measureGeojson: object) {
      state.measureGeojson = measureGeojson
    },
    setMeasureLinestring (state: any, measureLinestring: object) {
      state.measureLinestring = measureLinestring
    },
    resetMeasure (state: any) {
      state.totalDistance = null
      state.measureGeojson = {
        type: 'FeatureCollection',
        features: []
      }
      state.measureLinestring = {
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: []
        }
      }
    },
    resetStoreState (state: any) {
      state.dataLoaded = false
      state.mapLoaded = false
      state.map = null
      state.detailDrawerIsOpen = false
      state.selectedWellId = null
      state.selectedWell = null
      state.selectedStyle = 'satellite'
      state.totalDistance = null
      state.measureToolOn = false
      state.measureGeojson = {
        type: 'FeatureCollection',
        features: []
      }
      state.measureLinestring = {
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: []
        }
      }
    }
  }
}
