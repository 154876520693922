<template>
    <v-list-item class="py-0 w-100"
                :key="well.id"
                :to="{name: 'Well Details', params: { id: well.id, tenant: $store.state.currentTenant?.schemaName}}">

        <v-list-item-title class="text-body-1 font-weight-medium">{{ getWellLabel(well) }}</v-list-item-title>
        <v-list-item-subtitle class="text-body-2">{{ well.wellType.name }}</v-list-item-subtitle>

        <template v-slot:append>
          <div class="d-flex flex-column flex-1-1">
              <v-chip :class="['ben-chip-status']" class="align-self-end">
                <span
                    class="ben-chip-status-color"
                    :style="{ background: well.wellStatus.color?.hexCode }"
                ></span>
                {{well.wellStatus.name}}
              </v-chip>
              <p class="pt-1 text-right">{{ generateUpdatedCreatedString(well) }}</p>
          </div>
        </template>
    </v-list-item>
</template>

<script lang="ts">
import { Vue, Component, Prop, toNative } from 'vue-facing-decorator'
import DateUtils from '@/models/static/DateUtils'
import Well from '@/models/Well'

    @Component
class WellListItem extends Vue {
        @Prop well!: Well

        getWellLabel (well: Well): string {
          if (well.apiNumber === null || well.apiNumber === undefined) {
            return well.name as string
          } else {
            return well.name + ' - ' + well.apiNumber
          }
        }

        generateUpdatedCreatedString (well: Well) {
          return DateUtils.generateUpdatedCreatedString(well)
        }
    }
export default toNative(WellListItem)
</script>

<style>

</style>
