import { setContext } from '@apollo/client/link/context'
import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client/core'
import { createApolloProvider } from '@vue/apollo-option'
import ApolloLinkTimeout from 'apollo-link-timeout'

import { Auth } from '@aws-amplify/auth'
import Store from './stores'

const apiUri = process.env.VUE_APP_GRAPHQL_API_URI as string

// HTTP connection to the API
const httpLink = createHttpLink({
  uri: sessionStorage.getItem('customHeaders') ? apiUri.substring(0, apiUri.lastIndexOf('/')) + '/staging/graphql' : apiUri,
  credentials: 'include',
  fetchOptions: {
    mode: 'cors'
  },
  headers: JSON.parse(sessionStorage.getItem('customHeaders') ?? '{}')
})

const timeoutLink = new ApolloLinkTimeout(30000)
const timeoutHttpLink = timeoutLink.concat(httpLink)

const authLink = setContext(async (req, { headers }) => {
  const jwtToken = (await Auth.currentSession()).getIdToken().getJwtToken()

  return {
    headers: {
      ...headers,
      authorization: jwtToken,
      tenant_name: Store.state.currentTenant !== null && Store.state.currentTenant !== undefined ? (Store.state.currentTenant as any).schemaName as string : null
    }
  }
})

// Cache implementation
const cache = new InMemoryCache()

// Create the apollo client
export const apolloClient = new ApolloClient({
  link: authLink.concat(timeoutHttpLink),
  cache
})

export default createApolloProvider({
  defaultClient: apolloClient
})
