export const reloadWithCustomHeaders = async (customHeaders: {[key: string]: string}) => {
  const url = window.location.origin
  const headers = new Headers()

  for (const key in customHeaders) {
    headers.set(key, customHeaders[key])
  }

  try {
    const index = await fetch(url, {
      method: 'GET',
      headers,
      redirect: 'follow'
    })

    if (index.ok) {
      const html = await index.text()
      const parser = new DOMParser()
      const doc = parser.parseFromString(html, 'text/html')

      document.head.innerHTML = doc.head.innerHTML
      document.body.innerHTML = doc.body.innerHTML

      const scripts = Array.from<HTMLScriptElement>(doc.querySelectorAll('script[src]'))
      scripts.forEach(script => {
        const newScript = document.createElement('script')
        newScript.src = script.src
        document.body.appendChild(newScript)
      })

      // Reload external stylesheets
      const stylesheets = Array.from<HTMLLinkElement>(
        doc.querySelectorAll('link[rel="stylesheet"]'))
      stylesheets.forEach(stylesheet => {
        const newStylesheet = document.createElement('link')
        newStylesheet.rel = 'stylesheet'
        newStylesheet.href = stylesheet.href
        document.head.appendChild(newStylesheet)
      })

      // Inline scripts need to be executed manually
      const inlineScripts = Array.from<HTMLScriptElement>(
        doc.querySelectorAll('script:not([src])'))
      inlineScripts.forEach(inlineScript => {
        const newInlineScript = document.createElement('script')
        newInlineScript.text = inlineScript.text
        document.body.appendChild(newInlineScript)
      })
    } else {
      throw new Error('Network response was not ok')
    }
  } catch (error) {
    console.error('There was a problem with the fetch operation:', error)
  }
}

export const setValidationHeaders = () => {
  navigator.serviceWorker.controller?.postMessage({
    type: 'SESSION_STORAGE_DATA',
    value: { 'aws-cf-cd-validation-version': '1' }
  })
  sessionStorage.setItem('customHeaders', JSON.stringify({
    'aws-cf-cd-validation-version': '1'
  }))
  return reloadWithCustomHeaders({ 'aws-cf-cd-validation-version': '1' })
}

export const isValidating = () => {
  return sessionStorage.getItem('customHeaders')?.includes('aws-cf-cd-validation-version')
}
