import Well from '@/models/Well'

export default {
  namespaced: true,
  state () {
    return {
      model: null as Well | null,
      interestRowNum: null,
      aliasRowNum: null
    }
  },
  mutations: {
    setModel (state: any, model: any) {
      state.model = model
    },
    resetStoreState (state: any) {
      state.model = null
      state.interestRowNum = null
      state.aliasRowNum = null
    },
    setInterestRowNum (state: any, rowNum: any) {
      state.interestRowNum = rowNum
    },
    setAliasRowNum (state: any, rowNum: any) {
      state.aliasRowNum = rowNum
    }
  }
}
