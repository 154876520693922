import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "graph-legend" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.newLabels, (label, index) => {
      return (_openBlock(), _createElementBlock("span", {
        key: label,
        style: _normalizeStyle(`color: ${_ctx.colors[index]}`)
      }, _toDisplayString(label + (index+1 !== _ctx.newLabels.length ? " / " : "")), 5))
    }), 128))
  ], 512)), [
    [_vShow, _ctx.areUnitsLoaded]
  ])
}