<template>
    <div class="d-flex justify-center align-center">
        <v-skeleton-loader type="image" width="100%" height="100%" v-show="!loaded" class="background py-6 px-6"></v-skeleton-loader>
        <div class="text-subtitle-2" v-if="loaded && dataValues.length === 0">There are currently no wells in the system</div>
        <canvas ref="wellTypeCountChart" width="75" v-show="loaded && dataValues.length > 0" class="background py-6 px-6"></canvas>
    </div>
</template>

<script lang="ts">
import { Vue, Component, Ref, toNative } from 'vue-facing-decorator'
import gql from 'graphql-tag'
import { Chart, registerables } from 'chart.js'
import { ApolloComponentQueries } from '@/models/mixins/DataTableMixin'

Chart.register(...registerables)

    @Component
class WellTypeCountChart extends Vue {
      @Ref wellTypeCountChart!: HTMLCanvasElement

      private loaded = false
      private data: {id: number, code: string, name: string, count: number}[] = []
      private chart: Chart | null = null
      private chartColorPalette: string[] = ['#5F4690', '#1D6996', '#38A6A5', '#0F8554',
        '#73AF48', '#EDAD08', '#E17C05', '#CC503E',
        '#94346E', '#6F4070', '#994E95', '#666666',
        '#AC93DD', '#6AB6E3', '#85F3F2', '#5CD2A1',
        '#C0FC95', '#FFFA55', '#FFC952', '#FF9D8B',
        '#E181BB', '#BC8DBD', '#E69BE2', '#B3B3B3']

      get apollo (): ApolloComponentQueries {
        return {
          wellTypeCountQuery: {
            query: gql`query {
                              wellTypeCounts {
                                id
                                code
                                name
                                count
                              }
                            }`,
            update: (data: any) => {
              this.data = data.wellTypeCounts
              this.initialize()
              this.loaded = true
            }
          }
        }
      }

      mounted () {
        Object.keys(this.apollo ?? {}).forEach(key => {
          this.$apollo.addSmartQuery(key, this.apollo[key])
        })
      }

      initialize (): void {
        this.wellTypeCountChart.style.maxWidth = '1000px'
        this.wellTypeCountChart.style.maxHeight = '500px'
        this.chart = new Chart(
          this.wellTypeCountChart.getContext('2d') as CanvasRenderingContext2D, {
            type: 'pie',
            data: {
              datasets: [{
                data: this.dataValues,
                backgroundColor: this.chartColorPalette
              }],
              labels: this.dataLabels
            },
            options: {
              plugins: {
                legend: {
                  position: 'bottom'
                }
              }
            }
          } as any)
      }

      get dataValues (): number[] {
        return this.data.map((record) => record.count)
      }

      get dataLabels (): string[] {
        return this.data.map((record) => record.name)
      }
    }
export default toNative(WellTypeCountChart)
</script>
