<template>
    <v-card>
        <v-container>
            <v-skeleton-loader type="image" height="100%" width="100%" v-show="!loaded"></v-skeleton-loader>
            <div v-show="loaded" class="pb-6">
                <v-layout row class="px-4 pb-2">
                    <v-row class="xs9">
                        <div id="productionGraphLegend"></div>
                    </v-row>
                    <v-row class="xs3">
                        <v-btn size="x-small" variant="text" @click.stop="resetZoom()" id="graphResetButton">Reset Zoom</v-btn>
                        <v-btn size="x-small" variant="text" @click.stop="changeScale()" id="graphResetButton">{{ logscale ? "Log" : "Linear" }} Scale</v-btn>
                    </v-row>
                </v-layout>
                <div id="productionGraph"></div>
                <GraphLegend :labels="labels" :colors="colors" />
            </div>
        </v-container>
    </v-card>
</template>

<script lang="ts">
import { Component, Watch, Prop, mixins, toNative } from 'vue-facing-decorator'
import gql from 'graphql-tag'
import Dygraph from 'dygraphs'
import GraphLegend from '@/components/list/GraphLegend.vue'
import GraphMixin from '@/models/mixins/GraphMixin'
import Production from '@/models/Production'

    @Component({
      components: {
        GraphLegend
      }
    })
class MeterProductionGraph extends mixins(GraphMixin) {
        @Prop
          meterId!: string

        private productionData: Production[] = []
        private labels = ['Date', 'Gas Volume', 'Gas Energy', 'Oil Volume', 'Water Volume', 'Condensate Volume', 'NGL Volume', 'CO2 Volume', 'Sulfur Volume']
        private colors = ['#B05957', '#6E9954', '#c45883', '#3687B0', '#BA8F4F', '#C7BA66', '#000000', '#5F4690']

        get apollo () {
          return {
            productionQuery: {
              query: gql`query {
                                  totalProduction {
                                      periodStartDate,
                                      gasVolume,
                                      gasEnergy,
                                      nglVolume,
                                      oilVolume,
                                      waterVolume,
                                      condensateVolume,
                                      co2Volume,
                                      sulfurVolume
                                  }
                                }`,
              update: (data: any) => {
                this.productionData = data.totalProduction
                this.loaded = true
              }
            }
          }
        }

        drawGraph () {
          this.graph = new Dygraph('productionGraph',
            this.getFormattedData(),
            {
              labels: this.labels,
              colors: this.colors,
              strokeWidth: 3,
              logscale: this.logscale,
              hideOverlayOnMouseOut: false,
              labelsDiv: 'productionGraphLegend',
              labelsShowZeroValues: true,
              axes: {
                x: {
                  axisLabelWidth: 80
                },
                y: {
                  axisLabelWidth: 60
                }
              }
            })
        }

        getFormattedData () {
          if (this.productionData !== null) {
            return this.productionData.map(productionRecord => [
              new Date(productionRecord.periodStartDate?.substring(0, 10) as string),
              productionRecord.gasVolume,
              productionRecord.gasEnergy,
              productionRecord.oilVolume,
              productionRecord.waterVolume,
              productionRecord.condensateVolume,
              productionRecord.nglVolume,
              productionRecord.co2Volume,
              productionRecord.sulfurVolume
            ])
          } else {
            return []
          }
        }

        refresh () {
          this.$apollo.queries.productionQuery.refetch()
        }

        @Watch('loaded')
        onLoadedChange (newValue: any) {
          if (newValue === true) {
            setTimeout(() => this.drawGraph(), 100)
          }
        }
    }
export default toNative(MeterProductionGraph)
</script>

<style scoped>
    #graphResetButton {
        float: right;
    }

    #productionGraph {
        width: 100%;
        height: 400px;
    }
</style>
