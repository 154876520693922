import { Commit } from 'vuex'
export default {
  namespaced: true,
  state () {
    return {
      cache: []
    }
  },
  actions: {
    resetStoreState ({ commit } : {commit: Commit}) {
      commit('resetStoreState')
    }
  },
  mutations: {
    resetStoreState (state: any) {
      state.cache = []
    }
  }
}
