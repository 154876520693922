<template>
    <v-navigation-drawer
        class="notification"
        v-model="notificationDrawerOpen"
        :temporary="true"
        location="right"
        width="360"
        ref="navigationDrawer"
        :order="3"
    >
        <v-infinite-scroll :items="notifications" :onLoad="loadItems">
            <template v-slot:empty>
              <p v-if="notifications?.length > 0" class="text-center">All notifications displayed.</p>
              <p v-else class="text-center">No new notifications.</p>
            </template>
            <v-container fluid v-if="$store.getters['Notifications/hasData']">
                <v-row>
                    <v-col class="notification__item mb-0 pb-2 pt-2">
                        <button class="ben-btn text-center w-100" variant="text"
                                @click.stop="markAllNotificationsRead"
                        >
                          Mark all as read
                        </button>
                    </v-col>
                </v-row>
            </v-container>
            <div v-for="notification in notifications" :key="notification.id">
                <template v-if="notification.parameters && notification.parameters.internalLink">
                    <div
                        v-if="isOnLink(notification.parameters.internalLink)"
                        @click.prevent="reload(notification.parameters.internalLink)"
                        class="notification__item pointer-cursor"
                    >
                        <div class="notification__mount">
                            <div v-if="notification.read" class="notification__circle notification__circle--read"></div>
                            <div v-else class="notification__circle notification__circle--unread"></div>
                            <div class="notification__message">
                                <p>{{ notification.message }}</p>
                                <div class="notification__link">
                                    Click to view details >
                                </div>
                            </div>
                            <div class="notification__time">{{ localizedDate(notification.date) }}</div>
                        </div>
                        <div class="notification__divider"></div>
                    </div>
                    <router-link v-else
                                :to="getTo(notification.parameters.internalLink)">
                        <div class="notification__item pointer-cursor">
                            <div class="notification__mount">
                                <div v-if="notification.read" class="notification__circle notification__circle--read"></div>
                                <div v-else class="notification__circle notification__circle--unread"></div>
                                <div class="notification__message">
                                    <p>{{ notification.message }}</p>
                                    <div class="notification__link">
                                        Click to view details >
                                    </div>
                                </div>
                                <div class="notification__time">{{ localizedDate(notification.date) }}</div>
                            </div>
                            <div class="notification__divider"></div>
                        </div>
                </router-link>
                </template>
                <a v-else-if="notification.parameters && notification.parameters.externalLink"
                class="notification__item pointer-cursor"
                :href="notification.parameters.externalLink"
                target="_blank">
                    <div class="notification__mount">
                        <div v-if="notification.read" class="notification__circle notification__circle--read"></div>
                        <div v-else class="notification__circle notification__circle--unread"></div>
                        <div class="notification__message">
                            <p>{{ notification.message }}</p>
                            <div class="notification__link">
                                Click to view details >
                            </div>
                        </div>
                        <div class="notification__time">{{ localizedDate(notification.date) }}</div>
                    </div>
                    <div class="notification__divider"></div>
                </a>
                <div v-else class="notification__item">
                    <div class="notification__mount">
                        <div v-if="notification.read" class="notification__circle notification__circle--read"></div>
                        <div v-else class="notification__circle notification__circle--unread"></div>
                        <div class="notification__message">
                            <p>{{ notification.message }}</p>
                        </div>
                        <div class="notification__time">{{ localizedDate(notification.date) }}</div>
                    </div>
                    <div class="notification__divider"></div>
                </div>
            </div>
        </v-infinite-scroll>
    </v-navigation-drawer>
</template>

<script lang="ts">
import { Vue, Component, toNative } from 'vue-facing-decorator'
import moment from 'moment-timezone'
import store from '@/stores'

@Component
class NotificationDrawer extends Vue {
  moment = moment

  get notificationDrawerOpen (): boolean {
    return store.state.notificationDrawerOpen
  }

  set notificationDrawerOpen (notificationDrawerOpen: boolean) {
    store.commit('setNotificationDrawerOpen', notificationDrawerOpen)
  }

  get notifications () {
    return (store.state as any).Notifications.notifications as Notification[]
  }

  get hasNotifications () {
    return store.getters['Notifications/hasData']
  }

  async loadItems ({ done }: {done: any}) {
    if (this.hasNotifications) {
      await store.dispatch('getNotifications')
      if (this.notificationDrawerOpen) {
        await store.dispatch('Notifications/markNotificationsRead')
      }
      done('ok')
    } else {
      done('empty')
    }
  }

  localizedDate (date: string): string {
    return moment(
      date, 'YYYY-MM-DD hh:mm:ss Z'
    ).tz(moment.tz.guess()).fromNow()
  }

  isOnLink (internalLink: string): boolean {
    return internalLink === window.location.href ||
            window.location.pathname.includes(internalLink)
  }

  markAllNotificationsRead () {
    store.dispatch('Notifications/markAllNotificationsRead')
  }

  reload (link: string): void {
    if (link.includes('jobs')) {
      if (store.state.JobInfo.tab === 1) {
        store.commit('JobInfo/setRefresh', true)
      } else {
        store.commit('JobInfo/setTab', 1)
      }
    } else if (link.includes('reports')) {
      if (store.state.ReportInfo.tab === 1) {
        store.commit('ReportInfo/setRefresh', true)
      } else {
        store.commit('ReportInfo/setTab', 1)
      }
    } else {
      this.$router.go(0)
    }
  }

  getTo (link: string): object {
    const toObj = { params: { tab: 1, tenant: store.state.currentTenant!.schemaName as string } }
    if (link.includes('jobs')) {
      return {
        ...toObj,
        name: 'Jobs'
      }
    } else if (link.includes('reports')) {
      return {
        ...toObj,
        name: 'Reports'
      }
    }
    return {
      ...toObj,
      path: link
    }
  }
}
export default toNative(NotificationDrawer)
</script>

<style scoped>
p {
    margin: 0;
    padding: 0;
    border: 0;
    color: #243b53;
    font-weight: 400;
    line-height: 21px;
    font-size: 16px;
}

a {
    display: block;
    text-decoration: none;
}

.pointer-cursor {
    cursor: pointer;
}

.notification {
    z-index: 4;
    padding-bottom: 50px;
}

.notification,
.notification__item {
    background: #f5f7fa;
}

.notification__item {
    padding: 20px;
    position: relative;
    transition: background .3s;
    box-sizing: border-box
}

.notification__item:hover {
    background: #e8f0fa
}

.notification__mount {
    position: relative;
    width: 100%
}

.notification__circle {
    border-radius: 100%;
    height: 8px;
    left: 0;
    position: absolute;
    top: 4px;
    width: 8px
}

.notification__circle--unread {
    background: #0080DB
}

.notification__circle--read {
    background: #616E7C
}

.notification__message {
    box-sizing: border-box;
    color: #243b53;
    font-size: 18px;
    line-height: 21px;
    padding: 0 110px 0 18px;
    width: 100%
}

.notification__link {
    font-size: 14px;
    line-height: 18px;
    padding-top: 15px;
    color: #627d98 !important;
    font-weight: 600;
    transition: color .3s;
    text-decoration: none;
}

.notification__time {
    color: #627d98;
    font-size: 12px;
    line-height: 16px;
    position: absolute;
    right: 0;
    top: 4px
}

.notification__divider {
    background: #dbe2eb;
    bottom: 0;
    box-sizing: border-box;
    height: 1px;
    position: absolute;
    right: 0;
    width: calc(100% - 20px)
}
</style>
