export default interface AllocationMethod {
    id: number
    code: string
    referenceDescription: string
    referenceValue: string
}

export const allocationQueryName = (allocationMethod: string) => {
  switch (allocationMethod) {
    case 'Roll-Up':
    case 'ROLL_UP':
      return 'allocationResultsGasRollup'
    case 'Sales':
    case 'SALES':
      return 'allocationResultsGasSales'
    case 'Plant':
    case 'PLANT':
      return 'allocationResultsPlantGas'
    case 'Water':
    case 'WATER':
      return 'allocationResultsWater'
    default:
      return null
  }
}

export const allocationMutationName = (allocationMethod: string) => {
  switch (allocationMethod) {
    case 'Roll-Up':
    case 'ROLL_UP':
      return 'updateAllocationResultGasRollup'
    case 'Sales':
    case 'SALES':
      return 'updateAllocationResultGasSales'
    case 'Plant':
    case 'PLANT':
      return 'updateAllocationResultPlantGas'
    case 'Water':
    case 'WATER':
      return 'updateAllocationResultWater'
    default:
      return null
  }
}
