import { ThemeDefinition } from 'vuetify'

export default {
  dark: false,
  colors: {
    background: '#F5F7FA',
    primary: '#0080DB',
    secondary: '#FFFFFF',
    anchor: '#0080DB',
    error: '#E62233',
    info: '#0080DB',
    success: '#00A857',
    warning: '#FFB700'
  }
} as ThemeDefinition
