<template>
    <div class="d-flex justify-center align-center">
        <v-skeleton-loader type="image" width="100%" height="100%" v-show="!loaded" class="background py-6 px-6"></v-skeleton-loader>
        <div class="text-subtitle-2" v-if="loaded && dataValues.length === 0">There are currently no wells in the system</div>
        <canvas ref="wellStatusCountChart" width="75" v-show="loaded && dataValues.length > 0" class="background py-6 px-6"></canvas>
    </div>
</template>

<script lang="ts">
import { Vue, Component, toNative, Ref } from 'vue-facing-decorator'
import gql from 'graphql-tag'
import { ArcElement, Chart, PieController } from 'chart.js'
import { ApolloComponentQueries } from '@/models/mixins/DataTableMixin'

Chart.register(PieController, ArcElement)

@Component
class WellStatusCountChart extends Vue {
  @Ref wellStatusCountChart!: HTMLCanvasElement

  private loaded = false
  private data: object[] = []
  private chart: Chart | null = null
  private chartColorPalette: string[] = ['#CC503E', '#E17C05', '#EDAD08', '#73AF48',
    '#0F8554', '#38A6A5', '#1D6996', '#5F4690',
    '#94346E', '#6F4070', '#994E95', '#666666',
    '#AC93DD', '#6AB6E3', '#85F3F2', '#5CD2A1',
    '#C0FC95', '#FFFA55', '#FFC952', '#FF9D8B',
    '#E181BB', '#BC8DBD', '#E69BE2', '#B3B3B3']

  get apollo (): ApolloComponentQueries {
    return {
      wellStatusCountQuery: {
        query: gql`query {
                                  wellStatusCounts {
                                    id
                                    code
                                    name
                                    count
                                  }
                                }`,
        update: (data: any) => {
          this.data = data.wellStatusCounts
          this.initialize()
          this.loaded = true
        }
      }
    }
  }

  mounted () {
    Object.keys(this.apollo ?? {}).forEach(key => {
      this.$apollo.addSmartQuery(key, this.apollo[key])
    })
  }

  initialize (): void {
    this.wellStatusCountChart.style.maxWidth = '1000px'
    this.wellStatusCountChart.style.maxHeight = '500px'
    this.chart = new Chart(
            this.wellStatusCountChart.getContext('2d') as CanvasRenderingContext2D, {
              type: 'pie',
              data: {
                datasets: [{
                  data: this.dataValues,
                  backgroundColor: this.chartColorPalette
                }],
                labels: this.dataLabels
              },
              options: {
                plugins: {
                  legend: {
                    position: 'bottom'
                  }
                }
              }
            } as any)
  }

  get dataValues (): number[] {
    return this.data.map((record: any) => record.count)
  }

  get dataLabels (): string[] {
    return this.data.map((record: any) => record.name)
  }
}
export default toNative(WellStatusCountChart)
</script>

<style>

</style>
