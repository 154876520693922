import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { style: {"color":"black"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_snackbar = _resolveComponent("v-snackbar")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(`toast-parent ${_ctx.snackbarColor}`)
  }, [
    _createVNode(_component_v_snackbar, {
      modelValue: _ctx.active,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.active) = $event)),
      timeout: _ctx.timeout,
      color: "white",
      location: "top"
    }, {
      actions: _withCtx(() => [
        _createVNode(_component_v_btn, {
          variant: "text",
          onClick: _ctx.close,
          color: "black",
          size: "x-small",
          class: "ben-btn ben-btn--basic"
        }, {
          default: _withCtx(() => [
            _createTextVNode(" Close ")
          ]),
          _: 1
        }, 8, ["onClick"])
      ]),
      default: _withCtx(() => [
        (_ctx.type === 0)
          ? (_openBlock(), _createBlock(_component_v_icon, {
              key: 0,
              color: "orange"
            }, {
              default: _withCtx(() => [
                _createTextVNode("mdi-alert")
              ]),
              _: 1
            }))
          : (_ctx.type === -1)
            ? (_openBlock(), _createBlock(_component_v_icon, {
                key: 1,
                color: "red"
              }, {
                default: _withCtx(() => [
                  _createTextVNode("mdi-check")
                ]),
                _: 1
              }))
            : (_openBlock(), _createBlock(_component_v_icon, {
                key: 2,
                color: "blue"
              }, {
                default: _withCtx(() => [
                  _createTextVNode("mdi-check")
                ]),
                _: 1
              })),
        _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.title), 1)
      ]),
      _: 1
    }, 8, ["modelValue", "timeout"])
  ], 2))
}