<template>
    <v-container>
        <v-row xs12 wrap class="px-6">
            <v-col xs12 lg6 class="py-4 pt-8" align-self="center">
                <span class="text-h6">Wells by Type</span>
                <WellTypeCountChart class="pr-6 pt-4"></WellTypeCountChart>
            </v-col>
            <v-col xs12 lg6 class="py-4 pt-8" align-self="center">
                <span class="text-h6">Wells by Status</span>
                <WellStatusCountChart class="pr-6 pt-4"></WellStatusCountChart>
            </v-col>
        </v-row>
        <v-row xs12 class="py-8 px-6">
            <v-col>
                <span class="text-h6">Recently Updated Wells</span>
                <RecentlyUpdatedWellsList class="py-4"></RecentlyUpdatedWellsList>
            </v-col>
        </v-row>
    </v-container>
</template>

<script lang="ts">
import { Vue, Component, toNative } from 'vue-facing-decorator'
import TotalProductionGraph from '@/components/dashboard/TotalProductionGraph.vue'
import RecentlyUpdatedWellsList from '@/components/dashboard/RecentlyUpdatedWellsList.vue'
import WellTypeCountChart from '@/components/dashboard/WellTypeCountChart.vue'
import WellStatusCountChart from '@/components/dashboard/WellStatusCountChart.vue'

    @Component({
      components: {
        WellTypeCountChart,
        WellStatusCountChart,
        TotalProductionGraph,
        RecentlyUpdatedWellsList
      }
    })
class Dashboard extends Vue {}
export default toNative(Dashboard)
</script>
