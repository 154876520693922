import { resolveComponent as _resolveComponent, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "d-flex justify-center align-center" }
const _hoisted_2 = {
  key: 0,
  class: "text-subtitle-2"
}
const _hoisted_3 = {
  ref: "wellTypeCountChart",
  width: "75",
  class: "background py-6 px-6"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_skeleton_loader = _resolveComponent("v-skeleton-loader")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createVNode(_component_v_skeleton_loader, {
      type: "image",
      width: "100%",
      height: "100%",
      class: "background py-6 px-6"
    }, null, 512), [
      [_vShow, !_ctx.loaded]
    ]),
    (_ctx.loaded && _ctx.dataValues.length === 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, "There are currently no wells in the system"))
      : _createCommentVNode("", true),
    _withDirectives(_createElementVNode("canvas", _hoisted_3, null, 512), [
      [_vShow, _ctx.loaded && _ctx.dataValues.length > 0]
    ])
  ]))
}