<template>
    <div>
        <v-skeleton-loader type="image" width="100%" height="100%" v-show="!loaded"></v-skeleton-loader>
        <div class="text-subtitle-2" v-if="loaded && wells.length === 0">There are currently no well updates in the system</div>
        <v-card v-show="loaded && wells.length > 0" class="py-2">
            <v-row wrap>
                <v-col xs12 lg6 class="pa-0 ma-0 pr-3">
                    <v-list lines="two">
                      <v-list-subheader class="px-0 py-2" v-for="(well, index) in getMeterWellsHalf(1)" v-bind:key="well.id">
                        <WellListItem :well="well"></WellListItem>
                        <v-divider class="mt-2" v-if="index !== getMeterWellsHalf(1).length - 1"></v-divider>
                      </v-list-subheader>
                    </v-list>
                </v-col>
                <v-col xs12 lg6 class="pa-0 ma-0 pr-3">
                    <v-list lines="two">
                      <v-list-subheader class="px-0 py-2" v-for="(well, index) in getMeterWellsHalf(2)" v-bind:key="well.id">
                        <WellListItem :well="well"></WellListItem>
                        <v-divider class="mt-2" v-if="index !== getMeterWellsHalf(2).length - 1"></v-divider>
                      </v-list-subheader>
                    </v-list>
                </v-col>
            </v-row>
        </v-card>
    </div>
</template>

<script lang="ts">
import { Vue, Component, toNative } from 'vue-facing-decorator'
import gql from 'graphql-tag'
import DataStructureUtils from '@/models/static/DataStructureUtils'
import WellListItem from '@/components/wells/WellListItem.vue'
import { ApolloComponentQueries } from '@/models/mixins/DataTableMixin'

    @Component({
      components: {
        WellListItem
      }
    })
class RecentlyUpdatedWellsList extends Vue {
      private loaded = false
      private wells: object[] = []

      get apollo (): ApolloComponentQueries {
        return {
          wellsQuery: {
            query: gql`query($count: Int) {
                                    recentlyUpdatedWells (count: $count) {
                                      items {
                                        id,
                                        apiNumber,
                                        name,
                                        wellStatus {id, name, color {hexCode}},
                                        wellType {id, name}
                                        newestAuditEvent {id, auditEventActionCode, date}
                                      }
                                    }
                                  }`,
            variables: () => ({
              count: 10
            }),
            update: (data: any) => {
              this.wells = data.recentlyUpdatedWells.items
              this.loaded = true
            }
          }
        }
      }

      created () {
        Object.keys(this.apollo ?? {}).forEach(key => {
          this.$apollo.addSmartQuery(key, this.apollo[key])
        })
      }

      getMeterWellsHalf (half: number): object[] {
        return DataStructureUtils.getArrayFraction(JSON.parse(JSON.stringify(this.wells)), 2, half)
      }
    }
export default toNative(RecentlyUpdatedWellsList)
</script>

<style>

</style>
