import store from './global'
import listStore from './list-store'
import meterDetail from './meter-detail-store'
import wellDetail from './well-detail-store'
import wellboreDetail from './wellbore-detail-store'
import facilityDetail from './facility-detail-store'
import pipelineDetail from './pipeline-detail-store'
import reportingUnitDetail from './reporting-unit-detail-store'
import assetMap from './asset-map-store'
import companyAdmin from './company-admin-store'
import allocationDetailStore from './allocation-detail-store'
import tabStore from './tab-store'
import aliasStore from './alias-store'
import organizationDetail from './organization-detail-store'
import notifications from './notifications'
import cacheStore from './cache-store'

store.registerModule('WellList', listStore)
store.registerModule('FieldList', listStore)
store.registerModule('MeterList', listStore)
store.registerModule('MeterAliasList', listStore)
store.registerModule('OperatorAliasList', listStore)
store.registerModule('MeterInterestList', listStore)
store.registerModule('MeterXrefList', listStore)
store.registerModule('AddMeterList', listStore)
store.registerModule('EditMeterList', listStore)
store.registerModule('MeterFacilityHistoryList', listStore)
store.registerModule('MeterWellHistoryList', listStore)
store.registerModule('MeterPipelineHistoryList', listStore)
store.registerModule('WellboreList', listStore)
store.registerModule('ProductionList', listStore)
store.registerModule('AnalysisGasList', listStore)
store.registerModule('FacilityList', listStore)
store.registerModule('TenantList', listStore)
store.registerModule('AccountList', listStore)
store.registerModule('JobList', listStore)
store.registerModule('ReportingUnitList', listStore)
store.registerModule('ReportingUnitMeterList', listStore)
store.registerModule('ReportingUnitXrefList', listStore)
store.registerModule('ReportingUnitWellList', listStore)
store.registerModule('ReportingUnitWellboreList', listStore)
store.registerModule('ReportingUnitFacilityList', listStore)
store.registerModule('ReportingUnitPipelineList', listStore)
store.registerModule('FormulaReportingUnitList', listStore)
store.registerModule('EditCreateFormulaReportingUnitList', listStore)
store.registerModule('FormulaRuMeterList', listStore)
store.registerModule('FormulaRuRuList', listStore)
store.registerModule('FormulaBoxChipsList', cacheStore)
store.registerModule('PipelineList', listStore)
store.registerModule('PipelineSegmentList', listStore)
store.registerModule('PipelineInterestList', listStore)
store.registerModule('PipelineAliasList', listStore)
store.registerModule('ReportList', listStore)
store.registerModule('ReportRunList', listStore)
store.registerModule('MeterDetail', meterDetail)
store.registerModule('WellDetail', wellDetail)
store.registerModule('OrganizationDetail', organizationDetail)
store.registerModule('WellInterestList', listStore)
store.registerModule('WellAliasList', listStore)
store.registerModule('WellboreDetail', wellboreDetail)
store.registerModule('FacilityDetail', facilityDetail)
store.registerModule('FacilityInterestList', listStore)
store.registerModule('FacilityAliasList', listStore)
store.registerModule('PipelineDetail', pipelineDetail)
store.registerModule('ReportingUnitDetail', reportingUnitDetail)
store.registerModule('ReportingUnitAliasList', listStore)
store.registerModule('AllocationDetail', allocationDetailStore)
store.registerModule('AllocationSetList', listStore)
store.registerModule('AllocationResultList', listStore)
store.registerModule('OperatorList', listStore)
store.registerModule('AssetMap', assetMap)
store.registerModule('CompanyAdmin', companyAdmin)
store.registerModule('JobInfo', tabStore)
store.registerModule('ReportInfo', tabStore)
store.registerModule('PressureReadingList', listStore)
store.registerModule('CompanyInformationInfo', tabStore)
store.registerModule('OperatorAlias', aliasStore)
store.registerModule('Notifications', notifications)

export default store
